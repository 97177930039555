import React, { useEffect, useState } from "react";
import { getClasses, addTeacher } from "../services/tables";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "./common/input";
import Alert from "react-bootstrap/Alert";


function AddProfessor() {
  const schema = yup.object().shape({
    nome: yup.string().required("Campo Obrigatório"),
  });

  const [listClasses, setListClasses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    async function getAulas() {
      const result = await getClasses();
      setListClasses(result.data);
    }
    getAulas();
  }, []);
  
  // let navigate = useNavigate();
  // let path = "/professores";

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await addTeacher(data);
      setShowAlert(true);
    } catch (error) {
      setShowError(true);
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi Inserido com Sucesso
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-floating mb-3 mb-md-0">
              <Input
                className="form-control"
                id="nome"
                type="text"
                name="nome"
                register={register}
                label="Nome"
                placeholder="Professor"
              />
            </div>{" "}
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.nome?.message}
            </p>
          </div>
          <p>Selecionar Turma: </p>
          <div className="col-md-10">
            {listClasses.map((c, index) => {
              return (
                <div key={index} className="form-check form-check-inline">
                  <input
                    {...register("turmas")}
                    className="form-check-input"
                    type="checkbox"
                    id="turmas"
                    value={c.id_turma}
                  />
                  <label className="form-check-label" htmlFor="turmas">
                    {c.turmas}
                  </label>
                </div>
              );
            })}
          </div>
          <div
            className="mt-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <button type="submit" className="btn btn-success">
              Adicionar
            </button>
            <button type="reset" className="btn btn-danger">
              Apagar
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddProfessor;
