import React, { useEffect, useState } from "react";
import VotingCards from "./common/votingCards";
import { getProf, addVotos } from "../services/tables";
import { useForm } from "react-hook-form";
import UseModal from "./common/useModal";
import { useNavigate } from "react-router-dom";

function Votos({ turma }) {
  const [turmas, setTurmas] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  let navigate = useNavigate();

  useEffect(() => {
    window.sessionStorage.setItem("Turmas", JSON.stringify(turma));
  }, [turma]);

  useEffect(() => {
    async function getSource() {
      const result = await getProf(turma);
      setTurmas(result.data);
    }
    getSource();
  }, [turma]);

  const onSubmit = async (data) => {
    await addVotos(data)
   .then((res) =>
    res ? setShowResult(true) : setShowError(true)
     );
  };

  const handleback = () => {
    reset();
    let path = "/";
    navigate(path);
  };

  return (
    <div>
      <main style={{ backgroundColor: "#d2ddde" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-goup">
            <div className="container-fluid">
              <div className="col">
                <div className="form row">
                  {turmas.map((e, index) => {
                    return (
                      <VotingCards
                        key={index}
                        professor={e.nome}
                        ano={e.ano}
                        turma={e.turmas}
                        register={register}
                        id={index + 1}
                      />
                    );
                  })}

                  <UseModal
                    size={"lg"}
                    show={showResult}
                    close={() => {
                      setShowResult(false);
                      window.location.href = "/";
                    }}
                    body="Voto Submetido com Sucesso"
                    title="Resultado Voto"
                  />
                  <UseModal
                    size={"lg"}
                    show={showError}
                    close={() => {
                      setShowError(false);
                    }}
                    style={{ backgroundColor: "red" }}
                    body="Ocurreu um erro o voto não foi submetido tente novamente..."
                    title="Resultado Voto"
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button className="btn btn-success mt-3 mb-3">
                      <i className="bi bi-shift"> </i>
                      Submeter
                    </button>

                    <button
                      className=" btn btn-danger mt-3 mb-3 justify-content-end"
                      onClick={handleback}
                    >
                      <i className="bi bi-arrow-left-circle"> </i>
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
}

export default Votos;
