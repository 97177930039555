import React, { useEffect, useRef, useState } from "react";
import {
  getResult ,
  getTotalAlunos,
  getAbestinencias,
  getNumberVotes,
} from "../services/tables";
import { Bar, Pie } from "react-chartjs-2";
import UseModal from "./common/useModal";
import PDFVotes from "./pdfvotes";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


function Chart({ datas, filepdf, setFilePdf}) {
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [data6, setData6] = useState([]);

  const [totalVoters, setTotalVoters] = useState([]);
  const [abstinencia, setAbstinecia] = useState([]);
  const [numbVotes, setNumbVotes] = useState([]);

  useEffect(() => {
    async function getVotes() {
      await getResult(datas).then((res) => {
        //assiduidade
        const values1 = Object.values(res.data.assiduidade[0]).map((val) =>
          parseInt(val)
        );
        setData1(values1);
        //Expoe
        const values2 = Object.values(res.data.expoe[0]).map((val) =>
          parseInt(val)
        );
        setData2(values2);
        //aprende
        const values3 = Object.values(res.data.aprende[0]).map((val) =>
          parseInt(val)
        );
        setData3(values3);
        //motivado
        const values4 = Object.values(res.data.motivado[0]).map((val) =>
          parseInt(val)
        );
        setData4(values4);
        //relacionamento
        const values5 = Object.values(res.data.relacionamento[0]).map((val) =>
          parseInt(val)
        );
        setData5(values5);
        //continuar
        const values6 = Object.values(res.data.continuar[0]).map((val) =>
          parseInt(val)
        );
        setData6(values6);
      });
    }
    getVotes();
  }, [datas]);

  useEffect(() => {
    async function getVoters() {
      const totalresult = await getTotalAlunos(datas);
      setTotalVoters(totalresult.data);
    }
    getVoters();
    async function getAbs() {
      const totalabs = await getAbestinencias(datas);
      setAbstinecia(totalabs.data);
    }
    getAbs();
    async function getotalvoter() {
      const totalvotes = await getNumberVotes(datas);
     setNumbVotes(totalvotes.data);
    }
    getotalvoter();
  },[datas]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );

  const colors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
  ];
  //Label for chart
  const labels = ["Muito Mau", "Mau", "Satizfaz", "Bom", "Muito Bom"];
  // Opcoes do chart
  const options = [
    {
      responsive: true,

      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Assiduidade",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Expões a materia de forma clara",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Consegues aprender com as aulas",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Sentes motivado na aulas",
        },
      },
    },
    {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "Relacionamento do professor com a turma",
        },
      },
    },
  ];
  //Valores da basedados
  const values = [data1, data2, data3, data4, data5];

  //Pie chart
  const values1 = {
    labels: ["Sim", "Nāo"],
    datasets: [
      {
        label: "Continuar",
        data: data6,
        backgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const chartRef = useRef(null);
  const pieRef = useRef(null);

  return (
    <div>
      <h1>{datas.professor}</h1>
      <div ref={chartRef}>
        {values.map((value, index) => (
          <Bar
            data={{
              labels: labels,
              datasets: [
                {
                  data: value,
                  backgroundColor: colors,
                  borderColor: colors,
                  borderWidth: 1,
                  hoverBackgroundColor: colors,
                  hoverBorderColor: colors,
                },
              ],
            }}
            options={options[index]}
            key={index}
          />
        ))}
      </div>
      <br />
      <br />
      <div className="text-center">
        {" "}
        <small>Deseja Continuar</small>
      </div>
      <div ref={pieRef}>
        <Pie data={values1} />
        <div className="text-center">
        <small style={{fontWeight:"bold"}}>Sim:{data6[0]}  Nāo:{data6[1]}</small>

        </div>
      </div>

      <UseModal
        size={"lg"}
        show={filepdf}
        close={() => setFilePdf(false)}
        body={<PDFVotes chartRef={chartRef} pieRef={pieRef} datas={datas} totalVoters={totalVoters}  abstinencia={abstinencia} numbVotes={numbVotes}  />}
        title="Avaliaçāo do Professor"
      />
      <br />
    </div>
  );
}

export default Chart;
