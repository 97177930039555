import React, { useEffect, useState } from "react";
import { getClasses } from "../services/tables";
import MyNavbar from "./common/nav";
import Table from "react-bootstrap/Table";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "./common/useModal";
import EditTurma from "./editTurma";
import DelTurma from "./delTurma";
import AddTurma from "./addTurma";
import "../styles/dashboard.css";
import "../styles/background.css"

function Turmas() {
  const [listClasses, setListClasses] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);
  const [add, setAdd] = useState(false);

  const handleEdit = (edit) => {
    setEdit(true);
    setEditData(edit);
  };

  const handleDelete = (del) => {
    setDel(true);
    setDelData(del);
  };

  const handleadd = () => {
    setAdd(true);
  };

  useEffect(() => {
    async function getAulas() {
      const result = await getClasses();
      setListClasses(result.data);
    }
    getAulas();
  }, []);

  return (
    <>
      <div className="content">
        <MyNavbar />
        <div className="mt-3">
          <div className="col-md-8 col-xl-6 mx-auto">
            <div className="card">
              <div className="card-header">
                <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                  Alunos
                </h5>
              </div>
              <label htmlFor="" className="mt-1 text-center">
                <p style={{ fontStyle: "italic" }}>
                  CURSO DE ARQUITECTURA, URBANISMO E CONSTRUÇÃO
                </p>
              </label>
              <button
                className="btn btn-outline-success"
                onClick={() => handleadd()}
              >
                {" "}
                <i className="bi bi-plus-circle"> </i>Adicionar Turmas
              </button>
              <UseModal
                size={"lg"}
                show={add}
                close={() => setAdd(false)}
                body={<AddTurma />}
                title="Adicionar Turma"
              />

              <div className="card-body">
                <div className="mb-3"></div>
                <div className="mb-3">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Ano</th>
                        <th>Turma</th>
                        <th>Opcões</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listClasses.sort((a, b) => a.ano - b.ano).map((m, index) => {
                        return (
                          <tr key={index}>
                            <td>{m.ano}</td>
                            <td>{m.turmas}</td>
                            <td>
                              <DropdownButton
                                id="dropdown-basic-button"
                                title="Opções"
                                variant="secundary"
                              >
                                <Dropdown.Item
                                  onClick={() => handleEdit(m)}
                                >
                                  <i className="fa fa-edit">Editar</i>
                                </Dropdown.Item>
                                <UseModal
                                  size={"lg"}
                                  show={edit}
                                  close={() => window.location.reload(true)}
                                  body={<EditTurma {...editdata} />}
                                  title="Editar Turma"
                                />
                                <Dropdown.Item
                                  onClick={() => handleDelete(m)}
                                >
                                  <i className="fa fa-trash">Apagar</i>
                                </Dropdown.Item>
                                <UseModal
                                  show={del}
                                  close={() => window.location.reload(true)}
                                  body={<DelTurma {...deldata} />}
                                  title="Apagar Turma"
                                />
                              </DropdownButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Turmas;
