import http from "./httpServices";

export function addStudent(data) {
  return http.post("/add_student.php", data);
}

export function editStudent(data){
  return http.post("/edit_student.php", data)
}

export function getStudents() {
  return http.get("/get_students.php");
}

export function getClasses() {
  return http.get("/get_classes.php");
}

export function editClasses(data){
  return http.post("/edit_classes.php", data);
}

export function deleteClasses(data){
  return http.post("/delete_classes.php", data);
}

export function addTeacher(data) {
  return http.post("/add_professor.php", data);
}

export function getProf(data) {
  return http.post("/get_professor.php", data);
}

export function editProf(data) {
  return http.post("/edit_professor.php", data);
}

export function delProf(data){
  return http.post("/del_professor.php", data);
}

export function getClassesProf() {
  return http.get("/get_prof_classes.php");
}

export function addVotos(data) {
  return http.post("/add_votos.php", data);
}

export function addClasses(data) {
  return http.post("/add_turma.php", data);
}

export function getAllProf() {
  return http.post("/get_all_prof.php");
}

export function getResult(data) {
  return http.post("result.php", data);
}

export function getTotalAlunos(data) {
  return http.post("/get_total_students.php", data);
}

export function getAbestinencias(data) {
  return http.post("/get_abstinencia.php", data);
}

export function getNumberVotes(data) {
  return http.post("/get_student_votes.php", data);
}

export function getCount() {
  return http.get("/get_count.php");
}

export function publicar(data) {
  return http.post("/publicar.php", data);
}

export function getPublicar() {
  return http.get("/get_publicar.php");
}

export function delVotos(){
  return http.post("/del_votos.php");
}

