import React from "react";
import { delProf } from "../services/tables";

function DelProfessor(data) {

  const handleDelete = async () => {
    await delProf(data[0]).then((res) =>
      res ? window.location.reload(true) : null
    );
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar o professor{" "}
        <strong>{data[0].nome}</strong>
      </p>

      <button type="submit" className="btn btn-danger" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelProfessor;
