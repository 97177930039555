import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Votos from "./components/votos";
import Professores from "./components/professores";
import Home from "./components/home";
import Dashboard from "./components/dashboard";
import Turmas from "./components/turmas";
import Logout from "./components/logout";
import Resultados from "./components/resultados";
import Estudantes from "./components/estudantes";
import Layout from "./components/layout";
import NotFound from "./components/notFound";

function App() {
  const [turma, setTurma] = useState(null);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="/" element={<Home setTurma={setTurma} />} />
          <Route >
            <Route path="votos" element={<Votos turma={turma} />} />
            <Route path="turmas" element={<Turmas />} />
            <Route path="resultados" element={<Resultados />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="estudantes" element={<Estudantes />} />
            <Route path="professores" element={<Professores />} />
            <Route path="logout" element={<Logout />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
