import React, { useEffect, useState } from "react";
import MyNavbar from "./common/nav";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { getAllProf } from "../services/tables";
import Chart from "./chart";
import "../styles/background.css";

function Resultados() {
  const [allProf, setAllProf] = useState([]);
  const [votes, setVotes] = useState([]);
  const [showGraf, setShowGraf] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    async function getClass() {
      const result = await getAllProf();
      setAllProf(result.data);
    }
    getClass();
  }, []);

  
  const handleBack = () => {
    reset();
    setShowGraf(false);
    setShowPdf(false);
  };

  const handleExport = () => {
    setShowPdf(true);
  };

  //Filtrar resultado para eliminar duplicados
  var prof = allProf.map((p) => p.nome);
  const uniqueProf = [...new Set(prof)];

  const onSubmit = (data, e) => {
    e.preventDefault();
    setVotes(data);
    setShowGraf(true);
  };



  return (
    <div className="content" >
      <MyNavbar />
      <div className="mt-3">
        <div className="col-md-8 col-xl-4 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                Resultados
              </h5>
            </div>
            <label htmlFor="" className="mt-1 text-center">
              <p style={{ fontStyle: "italic" }}>
                CURSO DE ARQUITECTURA, URBANISMO E CONSTRUÇÃO
              </p>
            </label>
            <div className="card-body" >
              {!showGraf ? <h5>Selecionar</h5> : null}
              <form onSubmit={handleSubmit(onSubmit)}>
                {!showGraf ? (
                  <div>
                    <div className="mb-3">
                      <Form.Select
                        aria-label="Default select example"
                        {...register("professor")}
                      >
                        <option>Selecionar Professor</option>
                        {uniqueProf.map((t, index) => (
                          <option key={index} value={t}>
                            {t}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="mt-4 mb-0">
                      <div className="d-grid col-xl-1">
                        <button className="btn btn-info">Resultados</button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </form>
              {showGraf ? <Chart datas={votes} filepdf={showPdf} setFilePdf={setShowPdf} /> : null}
            </div>
            {showGraf ? (
              <div>
                <div className="card-footer">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button className="btn btn-danger" onClick={handleBack}>
                      <i className="bi bi-arrow-left-circle"> </i>
                      Voltar
                    </button>
                    <button className="btn btn-success" onClick={handleExport}>
                      <i className="bi bi-file-earmark-pdf"> </i>Exportar
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      
      </div>
    </div>
  );
}

export default Resultados;
