import React, { useState, useEffect } from "react";
import { getStudents, getPublicar } from "../services/tables";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

function Home({ setTurma }) {
  const schema = yup.object().shape({
    numero: yup
      .number()
      .required("Campo Obrigatório")
      .typeError("Numero Obrigatório"),
  });

  const [showButon, setShowButton] = useState(false);
  const [showName, setShowName] = useState();
  const [showVoto, setShowVoto] = useState(false);
  const [student, setStudent] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [online, setOnline] = useState();

  useEffect(() => {
    async function getOnline() {
      const result = await getPublicar();
      setOnline(result.data);
    }
    getOnline();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const result = await getStudents();
    const stud = result.data;

    var typenumber = JSON.stringify(data.numero);
    var obj = stud.filter((e) => {
      return e.numero === typenumber;
    });
    if (obj.length > 0) {
      setStudent(obj);
      setShowName(obj[0].nome);
      obj ? setShowButton(true) : setShowButton(false);
      sessionStorage.setItem("aluno", obj[0].numero);
    } else {
      setShowAlert(true);
    }
  };

  let navigate = useNavigate();

  const handleClick = () => {
    //Verfificar se o numero é do administrador
    let administrador = student[0].numero;
    if (administrador === "19012023") {
      let path = "dashboard";
      navigate(path);
    } else if (online === true) {
      //verificar se o aluno ja votou
      if (student[0].voto !== "0") {
        setShowVoto(true);
      } else {
        //Enviar para o cartao de votos
        let turmas = student[0].turmas;
        setTurma({ turmas });
        let path = "votos";
        navigate(path, { turmas });
      }
    }
  };

  return (
    <div>
      <main className="container-fluid">
        <div className="row mb-3 mt-5 ">
          <img
            src={process.env.PUBLIC_URL + "image/logo.webp"}
            alt="logo"
            className="img-fluid"
            style={{ maxWidth: "300px", margin: "auto" }}
          />
        </div>

        {showAlert ? (
          <Alert
            className="text-center"
            variant="danger"
            dismissible
            onClose={() => {
              setShowAlert(false);
              window.location.reload();
              sessionStorage.removeItem("aluno");
            }}
          >
            <strong> Numero Nāo Encontrado 😭😭!!!!</strong>
          </Alert>
        ) : null}

        {showVoto ? (
          <Alert
            className="text-center"
            variant="danger"
            dismissible
            onClose={() => {
              setShowVoto(false);
              window.location.reload();
              sessionStorage.removeItem("aluno");
            }}
          >
            <strong> O Voto ja descarregado 😭😭!!!!</strong>
          </Alert>
        ) : null}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.numero?.message}
            </p>
            <div className="d-grid gap-2 col-5 mx-auto">
              <div className="input-group mb-3 mb-md-0">
                {!showAlert && !showVoto ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Numero Aluno"
                    style={{ textAlign: "center" }}
                    {...register("numero")}
                  />
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-grid gap-2 col-4 mx-auto">
                <span className="badge bg-info text-dark">{showName}</span>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="row">
            <div className="d-grid gap-2 col-5 mx-auto">
              {!showButon ? (
                <button className="btn btn-success">Encontrar</button>
              ) : null}
              {showButon ? (
                <button className="btn btn-danger" onClick={handleClick}>
                  Votar
                </button>
              ) : null}
            </div>
          </div>
        </form>
      </main>
      <footer
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          margin: "auto",
          textAlign: "center",
          color: "black",
          fontStyle: "bold",
          backgroundColor: "white",
        }}
      >
        <small> Copyright © 2023 idealabangola.com 🇦🇴</small>{" "}
      </footer>
    </div>
  );
}

export default Home;
