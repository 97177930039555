import React from "react";
import { deleteClasses } from "../services/tables";

function DelTurma(data) {
  const handleDelete = async () => {
    await deleteClasses(data).then((res) =>
      res ? window.location.reload(true) : null
    );
  };

  return (
    <>
      <p>
        Tem a certeza que deseja apagar a Turma <strong>{data.turmas}</strong>
      </p>

      <button type="submit" className="btn btn-danger" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelTurma;
