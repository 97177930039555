import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import http from "../services/httpServices";

function DelUser(data) {
  const [danger, setDanger] = useState(false);

  const onSubmit = () => {
    http
      .post("/delete_students.php", data)
      .then((res) => (res.data ? (window.location.reload(true)) : null));
  };

  return (
    <div className="text-center">
      <Alert
        show={danger}
        variant="danger"
        dismissible
        onClose={() => setDanger(false)}
      ></Alert>
      <p>
        Tem a certeza que quer apagar permanentemente o aluno? &nbsp;&nbsp;
        <strong>
          {data.nome} {data.apelido}
        </strong>
      </p>
      <button className="btn btn-danger m-2" onClick={onSubmit}>
        Apagar
      </button>
    </div>
  );
}

export default DelUser;
