import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { getClasses, editStudent } from "../services/tables";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Alert from "react-bootstrap/Alert";

function EditStudent(data) {
  const [listClasses, setListClasses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [votos, setVotos] = useState();


  const id = data.id_alunos;
  const ano = data.ano;
  const nome = data.nome;
  const numero = data.numero;
  const turma = data.turmas;

  useEffect(() => {
    async function getAulas() {
      const result = await getClasses();
      setListClasses(result.data);
    }
    getAulas();
  }, []);

  useEffect(()=>{
    let voto = data.voto;
    if(voto === "0"){setVotos(false)}else{setVotos(true)};
  },[data.voto])

  const schema = yup.object().shape({
    nome: yup.string().required("Campo Obrigatório"),
    numero: yup.number().required("Campo Obrigatório").typeError("Obrigatório"),
    ano: yup.number().required("Campo Obrigatório").typeError("Obrigatório"),
    turmas: yup.string().required("Campo Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  var anolectivo = listClasses.map((e) => e.ano);
  const unique = [...new Set(anolectivo)];

  const onSubmit = async (data) => {
    await editStudent(data).then((res) => {
      res ? setShowAlert(true) : setShowError(true);
    });
  };

  return (
    <>
      <Alert
        show={showAlert}
        variant="success"
        dismissible
        onClose={() => window.location.reload(true)}
      >
        Foi modificado com sucesso...
      </Alert>
      <Alert
        show={showError}
        variant="danger"
        dismissible
        onClose={() => setShowError(false)}
      >
        Ocurreu um error
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="col-md-8">
            <div className="form-floating mb-3 mb-md-0">
              <input
                type="hidden"
                className="form-control"
                {...register("id", { value: id })}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-floating mb-3 mb-md-0">
              <input
                type="text"
                className="form-control"
                {...register("nome", { value: nome })}
              />
              <label htmlFor="nome">Nome</label>
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.nome?.message}
            </p>
          </div>
          <div className="col-md-4">
            <div className="form-floating mb-3 mb-md-0">
              <input
                type="text"
                className="form-control"
                {...register("numero", { value: numero })}
              />
              <label htmlFor="numero">Numero</label>
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.numero?.message}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="form-floating mb-3 mb-md-0">
              <Form.Select {...register("ano")}>
                <option>{ano}</option>
                {unique.map((e, index) => {
                  return <option key={index}>{e}</option>;
                })}
              </Form.Select>
              <label htmlFor="ano">Ano</label>
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.ano?.message}
            </p>
          </div>
          <div className="col-md-6">
            <div className="form-floating mb-3 mb-md-0">
              <Form.Select {...register("turmas")}>
                <option>{turma}</option>
                {listClasses.map((e, index) => {
                  return <option key={index}>{e.turmas}</option>;
                })}
              </Form.Select>
              <label htmlFor="turma">Turma</label>
            </div>
            <p style={{ color: "red", textAlign: "center" }}>
              {errors.turmas?.message}
            </p>
          </div>
          <div className="col-md-6 mt-3">
            <div className="form-floating mb-3 mb-md-0">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Votou"
                {...register("voto")}
                defaultChecked={votos}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 mb-0">
          <div className="d-grid">
            <button className="btn btn-danger">Editar Aluno</button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EditStudent;
