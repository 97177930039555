import React, { useEffect, useState } from "react";
import MyNavbar from "./common/nav";
import Table from "react-bootstrap/Table";
import { getClassesProf } from "../services/tables";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import UseModal from "./common/useModal";
import AddProfessor from "./addProfessor";
import Badge from "react-bootstrap/Badge";
import EditProfessor from "./editProfessor";
import DelProfessor from "./delProfessor";
import { useNavigate } from "react-router-dom";


function Professores() {
  const [allProf, setAllProf] = useState([]);
  const [add, setAdd] = useState(false);
  const [filteredTurmas, setFilteredTurmas] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditData] = useState({});
  const [deldata, setDelData] = useState({});
  const [del, setDel] = useState(false);

  useEffect(() => {
    async function getProf() {
      const result = await getClassesProf();
      setAllProf(result.data);
    }
    getProf();
  }, []);

  let navigate = useNavigate();
  let path = "professores";

  var prof = allProf.map((p) => p.nome);
  const uniqueProf = [...new Set(prof)];
  uniqueProf.sort((a, b) => a.localeCompare(b));

  const handleClick = (data) => {
    const filtered = allProf.filter((turma) => turma.nome === data);
    setFilteredTurmas(filtered);
  };

  const handleadd = () => {
    setAdd(true);
  };

  const handleEdit = (edit) => {
    const editFilter = allProf.filter((turma) => turma.nome === edit);
    setEdit(true);
    setEditData(editFilter);
  };

  const handleDelete = (del) => {
    const delFilter = allProf.filter((turma) => turma.nome === del);
    setDel(true);
    setDelData(delFilter);
  };

  return (
    <div>
      <MyNavbar />
      <div className="mt-3">
        <div className="col-md-8 col-xl-6 mx-auto">
          <div className="card">
            <div className="card-header">
              <h5 className="text-center mb-0" style={{ fontWeight: "bold" }}>
                Professores
              </h5>
            </div>
            <label className="mt-1 text-center">
              <p style={{ fontStyle: "italic" }}>
                CURSO DE ARQUITECTURA, URBANISMO E CONSTRUÇÃO
              </p>
            </label>
            <button
              className="btn btn-outline-success"
              onClick={() => handleadd()}
            >
              {" "}
              <i className="bi bi-plus-circle"> </i>Adicionar Professor
            </button>
            <UseModal
              size={"lg"}
              show={add}
              close={() => window.location.href="professores"}
              body={<AddProfessor />}
              title="Adicionar Professor"
            />
            <div className="card-body">
              <div className="mb-3"></div>
              <div className="mb-3">
                <Table responsive="sm" striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Turmas</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueProf.map((p, index) => {
                      return (
                        <tr key={index}>
                          <td>{p}</td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title="Turmas Atribuidas"
                              variant="light"
                              onClick={() => handleClick(p)}
                            >
                              {" "}
                              {filteredTurmas.map((t, index) => {
                                return (
                                  <Badge
                                    className="m-3"
                                    key={index}
                                    bg="primary"
                                  >
                                    {t.turmas}
                                  </Badge>
                                );
                              })}
                            </DropdownButton>
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              title="Opções"
                              variant="secundary"
                            >
                              <Dropdown.Item
                                onClick={() => handleEdit(p)}
                              >
                                <i className="fa fa-edit">Editar</i>
                              </Dropdown.Item>
                              <UseModal
                                size={"lg"}
                                show={edit}
                                close={() =>setEdit(false)}
                                body={<EditProfessor {...editdata} />}
                                title="Editar Professor"
                              />
                              <Dropdown.Item
                                onClick={() => handleDelete(p)}
                              >
                                <i className="fa fa-trash">Apagar</i>
                              </Dropdown.Item>
                              <UseModal
                                show={del}
                                close={() =>navigate(path)}
                                body={<DelProfessor {...deldata} />}
                                title="Apagar Professor"
                              />
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professores;
