import React, { useState, useEffect } from "react";
import { getCount, publicar, getPublicar } from "../services/tables";
import MyNavbar from "./common/nav";
import "../styles/dashboard.css";
import "../styles/background.css";
import Form from "react-bootstrap/Form";
import UseModal from "./common/useModal";
import DelVotos from "./delVotos";

function Dashboard() {
  const [totalAlunos, setTotalAlunos] = useState();
  const [totalProf, setTotalProf] = useState();
  const [totalTurma, setTotalTurma] = useState();
  const [totalVotosEfect, setTotalVotosEfect] = useState();
  const [online, setOnline] = useState();
  const [del, setDel] = useState(false);

  useEffect(() => {
    async function Total() {
      await getCount().then((res) => {
        setTotalAlunos(res.data.totalAlunos);
        setTotalProf(res.data.totalProf);
        setTotalTurma(res.data.totalTurma);
        setTotalVotosEfect(res.data.totalVotosEfect);
      });
    }
    Total();
  }, []);

  useEffect(() => {
    async function getOnline() {
      const result = await getPublicar();
      setOnline(result.data);
    }
    getOnline();
  }, []);

  const handleDelete = () => {
    setDel(true);
  };

  const handleClick = async (event) => {
    let online = event.target.checked;
    online ? (online = "1") : (online = "0");
    await publicar(online); // either true or false
  };

  return (
    <>
      <div className="content">
        {" "}
        <MyNavbar />
        <div className="text-center" style={{ color: "white" }}>
          <h1>Avaliaçāo de Professores</h1>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10">
              <div className="row">
                {/* Numero de Professores */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-green-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-user fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">
                          Numero de Professores
                        </h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalProf}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Numero de Turmas */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-cherry">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-file-o fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Numero Turmas</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalTurma}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Cartao dos alunos */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-blue-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-users fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Numero Total Alunos</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalAlunos}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Votos Descarregados */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-orange-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-spinner fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">
                          Numero de Votos Efectuados
                        </h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            {totalVotosEfect}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Publicar */}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-brown-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-archive fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Publicar Votaçāo</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Publicar"
                                onClick={handleClick}
                                defaultChecked={online}
                              />
                            </Form>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Apagar Votos*/}
                <div className="col-xl-3 col-lg-6">
                  <div className="card l-bg-green-dark">
                    <div className="card-statistic-3 p-4">
                      <div className="card-icon card-icon-large">
                        <i className="fa fa-times fa-5x"></i>
                      </div>
                      <div className="mb-4">
                        <h5 className="card-title mb-0">Apagar Votos</h5>
                      </div>
                      <div className="row align-items-center mb-2 d-flex">
                        <div className="col-8">
                          <h2 className="d-flex align-items-center mb-0">
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                handleDelete();
                              }}
                            >
                              Apagar
                            </button>
                          </h2>
                        </div>
                      </div>
                      <UseModal
                        size={"lg"}
                        show={del}
                        close={() => setDel(false)}
                        body={<DelVotos />}
                        title="Apagar Votos"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
