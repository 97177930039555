import React from "react";
import { delVotos } from "../services/tables";

function DelVotos() {
  const handleDelete = async () => {
    await delVotos().then(()=>{window.location.reload(true)});
  };

  return (
    <>
      <p>Tem a certeza que deseja apagar todos os votos!!!</p>

      <button type="submit" className="btn btn-danger" onClick={handleDelete}>
        Apagar
      </button>
    </>
  );
}

export default DelVotos;
